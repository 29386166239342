import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Landing from './Pages/Landing/LandingPage';
import Error from './Pages/ErrorPage/ErrorPage';
import './App.scss';

export default function App() {
  return (
    <div className='App'>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/*' element={<Error />} />
        </Routes>
    </div>
  );
}
